window.jQuery = $;
window.$ = $;

document.addEventListener("turbolinks:load", function () {
  // mask tel
  $("#supplier_phone_number").mask("(00) 0000-00009");
  $("#partner_phone_number").mask("(00) 0000-00009");

  $(".clientByEmail").on("blur", function () {
    let element = $(this);
    let clientUrl = element.data("client-url");
    let token = $('meta[name="csrf-token"]').attr("content");
    let email = element.val();
    let clientIdEl = $("#construction_client_id");

    clientIdEl.val("");

    if (!element.valid() || element.val().length < 1) {
      return;
    }

    $.ajax({
      url: clientUrl,
      type: "GET",
      data: { email: email },
      beforeSend: function (xhr) {
        xhr.setRequestHeader("X-CSRF-Token", token);
      },
      success: function (response) {
        $(".clientForm label, .clientForm input").each(function () {
          let el = $(this);

          el.attr("disabled", false);
          if (!el.hasClass("clientByEmail")) {
            el.val("");
            $("#construction_client_password").attr("required", true);
          }
        });
        if (response.status != "not_found") {
          $("#construction_client_name").val(response.client.name);
          $("#construction_client_document").val(response.client.document);
          $("#construction_client_rg").val(response.client.rg);
          $("#construction_client_rg_issuing_authority").val(
            response.client.rg_issuing_authority
          );
          $("#construction_client_marital_status").val(
            response.client.marital_status
          );
          $("#construction_client_nationality").val(
            response.client.nationality
          );
          $("#construction_client_phone_number").val(
            response.client.phone_number
          );

          clientIdEl.val(response.client.id);

          $("#construction_client_password").attr("required", false);
        }
      },
    });
  });
});
